var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"page-result",class:[
		..._vm.className,
		{
			'state-cards': _vm.showCards,
			'state-envelope': _vm.showEnvelope,
			'state-has-share': _vm.hasShare,
			'state-has-swapped': _vm.swapCount > 1,
			'state-share': _vm.selectedCard === 'buddy',
			'state-tryagain': _vm.selectedCard === 'prospect',
		},
	],on:{"click":_vm.Handle_OnClick}},[_c('section',{staticClass:"card-container"},[_c('ViewEnvelope',{class:{
				'state-moved': _vm.envelopeMoved,
				'state-opened': _vm.envelopeOpened,
			}},[_c('ViewCardStack',{ref:"cardStackView",class:{
					'can-swap': _vm.allowCardSwap,
					'state-closed': !_vm.showCards,
				},on:{"swap":_vm.Handle_OnSwap}})],1)],1),_c('div',{staticClass:"action-container"},[_c('div',{staticClass:"primary-action-container"},[_c('ViewButtonDownload',{nativeOn:{"click":function($event){return _vm.Handle_OnClickDownload.apply(null, arguments)}}}),_c('ViewButtonTryAgain',{nativeOn:{"click":function($event){return _vm.Handle_OnClickTryAgain.apply(null, arguments)}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }