
	import * as Manager from '@/Manager';
	import PageBase from '@/Page/Base.vue';
	import { Component, Ref } from 'vue-property-decorator';
	import { mounted } from '@/Utility/Decorators';
	import { matteImage } from '@/Utility/Image';
	import { share, shareMultipleFiles } from '@/Utility/Shareable';
	import { assetRef, downloadImagesFromUrls, sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project BunnyDragon
	 */
	@Component
	export default class PageResult extends PageBase {
		/**
		 * @var any
		 */
		@Ref('cardStackView')
		protected cardStackView!: any;

		/**
		 * Sets the animation state in the card stack once they're in place
		 *
		 * @var boolean
		 */
		protected allowCardSwap: boolean = false;

		/**
		 * Has a navigator.share
		 *
		 * @var boolean
		 */
		protected hasShare: boolean = navigator.share !== undefined;

		/**
		 * Brings cards larger and into focus
		 *
		 * @var boolean
		 */
		protected showCards: boolean = false;

		/**
		 * @var boolean
		 */
		protected showEnvelope: boolean = true;

		/**
		 * @var boolean
		 */
		protected envelopeMoved: boolean = false;

		/**
		 * @var boolean
		 */
		protected envelopeOpened: boolean = false;

		/**
		 * @var string
		 */
		protected selectedCard: string = '';

		/**
		 * @var number
		 */
		protected swapCount: number = 0;

		/**
		 * @return void
		 */
		protected async drawImages(): Promise<void> {
			const luckyBuddyBackgroundUrl: string = assetRef(`/image/background/buddy-${this.$store.getters.userZodiacElementSlug}.jpg`);
			const luckyBuddyCardUrl: string = this.$store.getters.luckyBuddyCardUrl;
			const prospectBackgroundUrl: string = assetRef(`/image/background/prospect-${this.$store.getters.userZodiacElementSlug}.jpg`);
			const prospectCardUrl: string = this.$store.getters.prospectCardUrl;

			// Draw the image
			const luckyBuddyCard = await matteImage(luckyBuddyBackgroundUrl, luckyBuddyCardUrl, 45);
			const prospectCard = await matteImage(prospectBackgroundUrl, prospectCardUrl, 45);

			// Download both cards
			downloadImagesFromUrls([
				{
					filename: 'zoodi-lucky-buddy.jpg',
					url: luckyBuddyCard,
				},
				{
					filename: 'zoodi-fortune.jpg',
					url: prospectCard,
				},
			]);
		}

		/**
		 * @return Promise<void>
		 */
		protected async openEnvelope(): Promise<void> {
			// Open envelope
			this.envelopeOpened = true;

			// Vibrate phone
			Manager.Haptics.vibrate();

			// Play audio
			Manager.Audio.playEnvelope();

			// Move the envelope
			await sleep(600);

			// Open envelope
			this.envelopeMoved = true;
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: PointerEvent): Promise<void> {
			e.preventDefault();

			if (!this.envelopeOpened) {
				// Open and wait
				await this.openEnvelope();

				// Wait more
				await sleep(600);

				// Bring forward the cards
				this.showCards = true;
				this.$forceUpdate();

				// Wait more
				await sleep(600);

				// Swap cards
				this.allowCardSwap = true;
				this.cardStackView.swap();
			} else {
				Manager.Audio.playFlipCard();
			}
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickDownload(e: PointerEvent): Promise<void> {
			e.preventDefault();

			this.drawImages();
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickShare(e: PointerEvent): Promise<void> {
			e.preventDefault();

			// Share for mobile
			const successful = await shareMultipleFiles(
				[this.$store.getters.luckyBuddyCardUrl, this.$store.getters.prospectCardUrl],
				this.$store.getters.shareableTitle,
				this.$store.getters.shareableText,
			);

			// Try something else
			if (!successful) {
				share(this.$store.getters.luckyBuddyCardUrl, this.$store.getters.shareableTitle, this.$store.getters.shareableText);
			}
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickTryAgain(e: PointerEvent): Promise<void> {
			e.preventDefault();

			this.animateOut();

			this.$router.push({
				name: 'PageInterlude',
			});
		}

		/**
		 * @param string cardType
		 * @return Promise<void>
		 */
		protected async Handle_OnSwap(cardType: string): Promise<void> {
			this.selectedCard = cardType;
			this.swapCount++;
		}
	}
