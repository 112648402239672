/**
 * @param string url
 * @param string title
 * @param string description
 * @param string image
 * @return void
 */
export function share(url: string, title: string, description: string) {
	shareNative(url, title, description);
}

/**
 * @param string url
 * @param string title
 * @param string description
 * @param string imageUrl
 * @return void
 */
function shareNative(url: string, title: string, description: string) {
	if (navigator.share) {
		navigator
			.share({
				// files: [imageUrl],
				text: description,
				title: title,
				url: url,
			})
			.then(() => console.log('Successful share'))
			.catch((error) => console.log('Error sharing', error));
	} else {
		shareBackup(url, title, description);
	}
}

/**
 * @param string url
 * @param string title
 * @param string description
 * @param string imageUrl
 * @return void
 */
function shareBackup(url: string, title: string, description: string) {
	window.open(url, '_blank');
}

/**
 * @param string type
 * @param string url
 * @return void
 */
export function shareSocial(type: string, url: string): void {
	// switch (type) {
	// 	case 'facebook':
	// 		shareSocialFacebook(url, title, description, imageUrl);
	// 		break;
	// 	case 'twitter':
	// 		shareSocialTwitter(url, title, description, imageUrl);
	// 		break;
	// 	case 'linkedin':
	// 		shareSocialLinkedin(url, title, description, imageUrl);
	// 		break;
	// 	case 'whatsapp':
	// 		shareSocialWhatsapp(url, title, description, imageUrl);
	// 		break;
	// 	case 'email':
	// 		shareSocialEmail(url, title, description, imageUrl);
	// 		break;
	// }

	switch (type) {
		case 'facebook':
			url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
			break;

		case 'twitter':
			url = 'https://twitter.com/home?status=Have%20a%20look%20at%3A%20' + encodeURIComponent(url);
			break;

		case 'google-plus':
			url = 'https://plus.google.com/share?url=' + encodeURIComponent(url);
			break;

		case 'linkedin':
			url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url);
			break;

		case 'xing':
			url = 'https://www.xing.com/app/user?op=share;url=' + encodeURIComponent(url);
			break;

		case 'vk':
			url = 'https://vk.com/share.php?url=' + encodeURIComponent(url);
			break;

		default:
	}

	if (type) {
		window.open(url, '_blank', 'location=no,height=300,width=500,scrollbars=yes,status=yes');
	}
}

/**
 * Tries to share multiple files or fallbacks to copying URLs to clipboard if sharing API is not available.
 *
 * @param images Array of image URLs to share.
 * @param title The title of the share.
 * @param description Optional description for the share.
 * @returns Promise that resolves to true if sharing was successful or fallback was executed, false if an error occurred.
 */
export async function shareMultipleFiles(images: string[], title: string, description: string = ''): Promise<boolean> {
	try {
		const filesArray: File[] = await Promise.all(images.map((image) => getFileFromUrl(image)));

		if ('share' in navigator) {
			await navigator.share({
				files: filesArray,
				text: description,
				title: title,
			});
		} else {
			// Fallback share mechanism, for example, copying the URLs to the clipboard
			const urlsText = images.join('\n');
			await navigator.clipboard.writeText(urlsText);
			console.log('Image URLs copied to clipboard as a fallback share mechanism.');
		}

		return true;
	} catch (e) {
		console.warn('Share failed:', e);
	}

	return false;
}

/**
 * Fetches a file from a given URL.
 *
 * @param url The URL to fetch the file from.
 * @returns A promise that resolves to the File object fetched from the URL.
 */
async function getFileFromUrl(url: string): Promise<File> {
	try {
		const response = await fetch(url);

		// Check for HTTP-specific errors
		if (!response.ok) {
			throw new Error(`HTTP error: ${response.status} ${response.statusText}`);
		}

		const data = await response.blob();
		const filename = url.split('/').pop() || 'default_filename';
		return new File([data], filename, { type: data.type });
	} catch (e) {
		console.warn('Failed to get file from URL:', e);

		// Handle network errors or other fetch issues
		throw new Error('Network error or CORS policy prevents fetching the resource.');
	}
}
