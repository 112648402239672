/**
 * @param string backgroundImageUrl
 * @param string foregroundImageUrl
 * @returns Promise<string>
 */
export async function matteImage(backgroundImageUrl: string, foregroundImageUrl: string, radius: number = 50): Promise<any> {
	const [backgroundImage, foregroundImage] = await Promise.all([loadImage(backgroundImageUrl), loadImage(foregroundImageUrl)]);

	const canvas = createCanvas(backgroundImage.width, backgroundImage.height);
	const context = canvas.getContext('2d') as CanvasRenderingContext2D;

	// Draw background
	context.drawImage(backgroundImage, 0, 0);

	// Calculate margins and new width and height for the foreground image
	const margin = backgroundImage.width * 0.05; // 10% margin on each side
	const targetWidth = backgroundImage.width - 2 * margin; // Adjusted width for the foreground image
	const scale = targetWidth / foregroundImage.width; // Calculate scale to maintain aspect ratio
	const targetHeight = foregroundImage.height * scale; // Adjusted height based on the scale
	const x = margin; // Start drawing from the left margin
	const y = (backgroundImage.height - targetHeight) / 2; // Center the image vertically

	// Shadow properties
	// Shadow properties
	context.shadowBlur = 10;
	context.shadowColor = 'rgba(0, 0, 0, 0.25)';
	context.shadowOffsetX = 1;
	context.shadowOffsetY = 5;

	// Draw a background for the shadow
	// This will be a solid fill underneath where the foreground image will go
	context.fillStyle = '#000000'; // Use a solid fill color similar to the canvas or background
	roundedRect(context, x + 1, y + 1, targetWidth - 2, targetHeight - 2, radius);
	context.fill(); // Fill the shadow background

	// Reset shadow properties before drawing the image
	context.shadowBlur = 0;
	context.shadowColor = 'transparent';
	context.shadowOffsetX = 0;
	context.shadowOffsetY = 0;

	// Clip the region where the foreground image will be drawn
	context.save(); // Save the current state before clipping
	roundedRect(context, x, y, targetWidth, targetHeight, radius);
	context.clip();

	// Draw foreground image within the clipped region, without shadow
	context.drawImage(foregroundImage, x, y, targetWidth, targetHeight);

	context.restore(); // Restore the context to its state before clipping

	// Draw the scaled foreground image
	// context.drawImage(foregroundImage, x, y, targetWidth, scaledHeight);

	// Optionally return the canvas or its data URL
	return canvas.toDataURL();
}

/**
 * @param string url
 * @returns Promise<HTMLImageElement>
 */
async function loadImage(url: string): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const image = new Image();
		image.crossOrigin = 'anonymous';
		image.onerror = reject;
		image.onload = () => resolve(image);
		image.src = url;
	});
}

/**
 * @param number width
 * @param number height
 * @returns HTMLCanvasElement
 */
function createCanvas(width: number, height: number): HTMLCanvasElement {
	const canvas = document.createElement('canvas');
	canvas.height = height;
	canvas.width = width;
	return canvas;
}

/**
 * @param CanvasRenderingContext2D context
 * @param number x
 * @param number y
 * @param number width
 * @param number height
 * @param number radius
 * @returns void
 */
function roundedRect(context: CanvasRenderingContext2D, x: number = 0, y: number = 0, width: number = 320, height: number = 640, radius: number = 5) {
	context.beginPath();
	context.moveTo(x + radius, y);
	context.lineTo(x + width - radius, y);
	context.quadraticCurveTo(x + width, y, x + width, y + radius);
	context.lineTo(x + width, y + height - radius);
	context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
	context.lineTo(x + radius, y + height);
	context.quadraticCurveTo(x, y + height, x, y + height - radius);
	context.lineTo(x, y + radius);
	context.quadraticCurveTo(x, y, x + radius, y);
	context.closePath();
}
